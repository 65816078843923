
.story-title {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial;
  font-size: 40px;
  font-weight: 300;
  line-height: 70px;
  margin-left: 10px;
}

#app-body {
  // display: table;
}

$body-bg: #e4e5e6;
$body-color: #23282c;

// Assumes the browser default, typically `16px`
$font-size-base: 0.8rem;

// RoundPoint blue
//$blue: #40B4E2;

$foo: #63656B;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';


