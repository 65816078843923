
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.global-nav {
  display: block;
  overflow: auto;
  box-sizing: border-box;

  width: 100%;
  line-height: 24px;

  z-index: 9999;

  border: none;
  // border-bottom: 1px solid #c8ced3;
}

.global-nav-wrapper {
  top: 0;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  height: 60px;

  background: #141E30;
  background: -webkit-gradient(linear, left top, right top, from(#0c2646), color-stop(60%, #204065), to(#2a5788));
  background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
}

.global-nav-suffix {
  height: 60px;
}

.global-nav-left {

}

.global-nav-title {
  padding-top: 12px;
  font-size: 1.8rem;
  font-weight: 300;
  color: #FFF;
  white-space: nowrap;
}

.global-nav-right {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  justify-content: flex-end;
}


.global-nav-logo-item {
  width: 105px;
  height: 55px;
  padding-top: 6px;
  padding-left: 25px;
  background-color: transparent;
  border: none;
}
.global-nav-logo-item:hover {
  // background-color: #333;
  opacity: 0.8;
}

.global-nav-logo {
  opacity: 1;
}

.global-nav-item {
  height: 30px;
  line-height: 30px;
  padding: 0;
  vertical-align: middle;
  color: #FFF;
  font-size: 14px;
}

.global-nav-icon {
  color: #FFF;
}
.global-nav-icon:hover {
  opacity: 0.8;
}





.sidebar {
  position: fixed;
  width: 420px;
  height: 100%;
  top: 0;
  left: -420px;
  z-index: 9999;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 60px;

  // background: #141E30;
  // background: -webkit-gradient(linear, left top, right top, from(#0c2646), color-stop(60%, #204065), to(#2a5788));
  // background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
}

.sidebar-open {
  left: 0;
}

.sidebar .closeButton {
  position: absolute;
  top: 10px;
  right: 25px;
  margin-left: 50px;
}

.sidebar-nav-item {
  font-size: 1.4em;
  line-height: 1em;
  padding-bottom: 1.6em;
  text-decoration: none;
  font-weight: 200;
}
.sidebar-nav-item:hover {
  font-weight: 400;
}

.sidebar-nav-item-icon { 
  color: rgb(255, 255, 255);
}

.sidebar-nav-item-title {
  text-decoration: none;
  color: rgb(255, 255, 255);
}


.sidebar-nav-item-description {
  font-size: 0.6em;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }

  .sidebar a {
    font-size: 18px;
  }
}

